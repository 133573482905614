import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import MainContent from './MainContent'
import './sidebar.scss'

const SidebarLayout = ({ children }) => {
  const [expanded, setExpanded] = useState(false)

  const toggleSidebar = () => {
    setExpanded(!expanded)
  }

  const [isLoginPage, setIsLoginPage] = useState(false)

  useEffect(() => {
    // Ensure this code runs only in the client environment.
    if (typeof window !== 'undefined') {
      setIsLoginPage(window.location.pathname === '/login')
    }
  }, [])
  

  return (
    <main>
      {!isLoginPage && <Sidebar expanded={expanded} />}
      <MainContent expanded={expanded} isLoginPage={isLoginPage} toggleSidebar={toggleSidebar}>
        {children}
      </MainContent>
    </main>
  )
}

export default SidebarLayout
